import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.css']
})
export class FileSelectorComponent {
    //VARIABLES
    selectedFile: File | null = null;
    @Output() emitFile = new EventEmitter<any>();
    selectedFiles: File[] = []; 
    @Input() allowMultiple: boolean = false;
    @Input() acceptedFiles: string; 
  
    //FUNCTIONS
    triggerFileInput() {
      if(this.selectedFiles.length < 1){
        const fileInput = document.querySelector<HTMLInputElement>('#fileInput');
        fileInput?.click();
      }
     
    }

    onFileSelected(event: any) {
     
      const input = event.target as HTMLInputElement;
      const files = input.files;
            if (files && files.length > 0) {
                 if (this.allowMultiple) {
        for (let i = 0; i < files.length; i++) {
          this.selectedFiles.push(files[i]);
        }
        this.emitFile.emit(this.selectedFiles)
      } else {
       this.selectedFiles = [files[0]];
        this.emitFile.emit(files[0])
      }

      }
  
   
      
    }
  
    removeFile(index: number) {
      this.selectedFiles.splice(index, 1);
      if(this.allowMultiple){
        this.emitFile.emit(this.selectedFiles)
      }else {
        this.selectedFiles = [];
        this.emitFile.emit(null)
      }
      
    }

}
