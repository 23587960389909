<div class="modal-body" style="width: 579px">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h5 class="modal-title w-300" id="uploadExcelModalLabel">{{allowMultiple ? "Upload Files" : "Upload File"}}</h5>
      <button
        type="button"
        class="btn-close"
        (click)="onCloseModalCLick()"
      ></button>
    </div>
    <span *ngIf="info" class="" style="font-size: small; color: gray">
      {{info}}
    </span>
    <span *ngIf="subInfo" class="" style="font-size: small; color: gray">
      {{subInfo}}
    </span>
    <p class="my-1">{{allowMultiple ? "Select Files" : "Select File"}}</p>
    <lib-file-selector
      (emitFile)="getFileDetails($event)"
      [allowMultiple] = "allowMultiple"
      [acceptedFiles] = "acceptedFiles"
      [validation]="validation"


    ></lib-file-selector>
    <lib-button
      text="Submit"
      [isFullWidth]="true"
      [isDisabled]="allowMultiple ? selectedFile?.length === 0 : selectedFile === null "
      (click)="onUploadNextGenFileClick()"
    ></lib-button>
  </div>

